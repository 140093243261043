.profile-page__headline {
    margin-top: 50px;
    margin-bottom: 17px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
}

.profile-line {
    margin-bottom: 26px;
}

.profile-page__info-grid {
    display: grid;

    margin-bottom: 35px;
}

.info-grid__balance-wrap {
    grid-column-start: 1;
    grid-column-end: 2;

}
.info-grid__status-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
}
.balance-up {
    grid-column: 3 / 4;
    grid-row: 2 / 3;

    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-grid__sub-status {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.info-grid__atandance {
    grid-column: 2 / 3;

}

.subscribe__btn {
    grid-column: 4 / 6;
    grid-row: 1 / 3;
    justify-self: end;

    box-sizing: border-box;
    width: 460px;
    height: auto;
    padding: 6px 38px;
    border: none;

    box-sizing: border-box;
    background: #F9B233;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    cursor: pointer;

}

.subscribe__btn:hover {
    opacity: .8;
}

.info-grid__balance-wrap span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;

    color: #000000;
}

.info-grid__item {
    margin-bottom: 18px;
}

.info-grid__item b {
    display: flex;
    align-items: center;
    width: 80px;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;

    color: #000000;
}

.info-grid__atandance b,
.info-grid__sub-status b {
    width: 125px;
}



.info-grid__item span {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;

    color: #000000;
}

.info-grid__item {
    display: flex;
}


.balance-up {
    box-sizing: border-box;

    margin-top: -10px;
    margin-right: 10px;

    background: #FF390F;
    border-radius: 10px;
    
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    cursor: pointer;

    border: 0;

    outline: none;
}

.balance-up:hover {
    opacity: .8;
}

.subscribe-btn__cta {
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;

    color: #FFFFFF;
}

.subscribe-btn__description{
    font-size: 14px;
    text-align: center;

    color: #FFFFFF;
}

.profile__achievements-grid {
    background: #16A7E1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 45px;
}

.desc__item--white .desc-item__number,
.desc__item--white .desc-item__content
{
    color: #fff;
}

.profile__awards {
    margin-bottom: 45px;
}

.profile-page__h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;

    color: #000000;
    margin-bottom: 35px;
}

.awards-list {
    display: flex;
    flex-wrap: wrap;
}

.awards-list__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 225px;
    margin-right: 50px;
    margin-bottom: 25px;
}

.awards-list__item img{
    grid-row: 1 / 3;
    margin-top: -13px;
    width: 125px;
    height: 125px;
}

.awards-list__item h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;

    color: #000000;

}

.awards-list__item p {
    width: 125px;

    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 16px;

    color: #000000;

    grid-column: 2 / 3;
}

.profile__certificates {
    margin-bottom: 45px;
}

.certificates-list {
    display: flex;
}

.certificates-list__item {
    width: 144px;
    height: 215px;
    background-image: url("https://homing.yescoding.ru/uploads/19012021-131338-alexkem.jpg");
    background-size: cover;
    margin-right: 25px;
}

.activity {
    margin-bottom: 25px;
}

.active-table__item {
    padding-left: 35px;
    display: flex;
    margin-bottom: 15px;
}

.active-table-item__title {
    width: 214px;

    font-weight: 900;
    font-size: 16px;
    line-height: 19px;

    color: #16A7E1;
}

.active-table-item__date {
    width: 150px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.active-table-item__sum{
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.info-grid__pay-field {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.info-grid__pay-field b {
    width: 80px;
    display: flex;
    align-items: center;
}

#amount {
    height: auto;
    width: 83px;
    border-radius: 20px;
    border: 1px solid;
    text-align: center;
}

#amount::-webkit-inner-spin-button {
	display: none;
}

.modal {
    position: fixed;
    width: 600px;
    height: 400px;
    top: 50%;
    margin-top: -300px;
    left: 50%;
    margin-left: -300px;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    box-sizing: border-box;
    padding: 35px 35px;

    border-radius: 20px;
}
.modal-podlozhka {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999;
}

.modal h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 15px;
}

.modal p{
    font-size: 20px;
    margin-bottom: 35px;
}

.modal button,
.modal a{ 
    margin: 0 auto; 
}

.sub-update-modal {
    height: 250px;
    margin-top: -125px;
}

.modal-loading{
    position: absolute;
    top: 10%;
    left: 35%
}

.btn-loader {
    position: relative;
    top: -82px;
    left: 40px;
}


@media screen and (max-width: 600px){
    .active-table-item__date {
        width: 90px;
    }

    .active-table-item__title {
        width: 100px;
    }
    .profile-page__headline {
        padding-top: 15px;
    }
    .info-grid__pay-field{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    .subscribe__btn{
        grid-column: 1 / 3;
        grid-row: 4 / 6;
    }
    
    .balance-up {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }

    .info-grid__item {
        margin-right: 30px;
    }
}

@media screen and (max-width: 450px){
    .info-grid__sub-status{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .info-grid__atandance{
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }

    .info-grid__pay-field{
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }

    .balance-up {
        grid-column: 1 / 2;
        grid-row: 6 / 7;
        margin: 0;
    }

    .subscribe__btn{

        grid-row: 7 / 9;
        width: initial;
        margin-top: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .info-grid__item b {
        width: 125px;
    }

    .profile__achievements-grid {
        flex-direction: column;
        width: 90%
        
    }

    .desc-item__number {
        width: 82px;
    }
}

