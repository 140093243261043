/* .auth-modal {
    position: absolute;
    top: 57px;
    
    width: 450px;
    right: 15%;
} */


.auth-modal {
    box-sizing: border-box;
    padding: 30px;
    width: 400px;
    min-height: 371px;

    background: #16A7E1;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    justify-self: right;
    align-self: center;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.auth-card{
    min-height: 371px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.card-title {
    font-weight: 800;
    font-size: 24px;
    color: #fff;
}

.auth-card input, #first_name, #second_name{
    padding: 2px 15px;
    color: #000;
    border: 2px solid #e3e3e3;
    border-radius: 30px;
    width: 300px;
    height: 30px;
    outline: 0;
    background-color: #fff;
    /* text-align: center; */
}

.auth_name-input {
    margin-bottom: 20px;
}

.auth__enter-btn{
    cursor: pointer;    
    font-size: 16px;
    padding: 2px 25px;
    font-weight: 800;
    background: #F9A41F;
    border: 0;
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth__reg-btn {
    cursor: pointer;
    font-size: 16px;
    padding: 2px 25px;
    font-weight: 800;
    background: #ff390f;
    border: 0;
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth__back-to-enter-btn {
    font-size: 14px;
    font-weight: 800;
    color: rgb(22, 0, 162);
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(22, 0, 162);
    padding: 0;
}

.auth-page__buttons {
    display: flex;
    justify-content: space-between;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

#email,
#password{
    /* border-bottom: 1px solid #fff; */
}
#email.valid,
#password.valid,
#email:focus,
#password:focus
{
    border-bottom: 1px solid #26a69a;
}

#email.invalid,
#password.invalid
{
    border-color: #F44336;
}

@media screen and (max-width: 650px){
    .auth-modal {
        right: 0;
        width: 100%;
        top: 48px;
    }
}


