/* @import '~materialize-css/dist/css/materialize.css'; */
@import './styles/loading.css';
@import './styles/exercise.css';
@import './styles/navbar.css';
@import './styles/auth-page.css';
@import './styles/groups-list.css';
@import './styles/user-item.css';
@import './styles/course.css';
@import './styles/fonts.css';
@import './pages/components/turtle/turtleEx.css';
@import './styles/main-page.css';
@import './styles/pricepage.css';
@import './styles/profile.css';
@import './styles/achivment-popup.css';
@import './styles/turtle-ex.css';
@import './styles/order.css';
@import './styles/transaction.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, sans-serif;
    color: rgba(0,0,0,0.87);
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.homing-btn {
    padding: 10px 70px;
    background: #F9A41F;
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
    width: fit-content;

    cursor: pointer;
    border: none;
    outline: none;
}

.homing-btn:hover {
    opacity: .8;
}

.homing-btn-yellow { 
    background: #F9A41F;
}

.homing-btn-blue { 
    background: #16A7E1;
}
.homing-btn-red { 
    background: #FF390F;
}

.input-field {
    position: relative;
}

.close {
position: absolute;
right: 32px;
top: 32px;
width: 32px;
height: 32px;
opacity: 0.3;
cursor: pointer;
}
.close:hover {
opacity: 1;
}
.close:before, .close:after {
position: absolute;
left: 15px;
content: ' ';
height: 33px;
width: 2px;
background-color: #333;
}
.close:before {
transform: rotate(45deg);
}
.close:after {
transform: rotate(-45deg);
}

h3 {
    font-size: 42px;
}

.toast {
    border-radius: 2px;
  top: 35px;
  width: auto;
  margin-top: 10px;
  position: relative;
  max-width: 100%;
  height: auto;
  min-height: 48px;
  line-height: 1.5em;
  background-color: #323232;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: default;
}

#toast-container {
    display: block;
    position: fixed;
    z-index: 10000;
  }

  
@media only screen and (min-width: 601px) and (max-width: 992px) {
    #toast-container {
      left: 5%;
      bottom: 7%;
      max-width: 90%;
    }
  }
  
  @media only screen and (min-width: 993px) {
    #toast-container {
      top: 10%;
      right: 7%;
      max-width: 86%;
    }
  }

input[type=text] {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 30px;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 3px;
    box-shadow: none;
    box-sizing: content-box;
    transition: box-shadow .3s, border .3s;
    
}


.input-field>label {
    position: absolute;
    top: -3px;
    left: 20px;
    font-size: 1rem;
    cursor: text;
    transition: transform .2s ease-out, color .2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);
    font-weight: 800;
}

.input-field>label.active {
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0;
    color: #fff;
}

.container {
    width: 1100px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px){

.container {
    width: 900px;
}

}

@media screen and (max-width: 950px){

    .container {
        width: 650px;
    }
    
}

@media screen and (max-width: 700px){

    .container {
        width: 420px;
    }
}

@media screen and (max-width: 450px){

    .container {
        width: 320px;
    }
    
}


.btn, .btn-small {
    border: none;
    border-radius: 2px;

    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.btn, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: #26a69a;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

select {
    display: initial;
    margin-bottom: 10px;
}


.p-t {
    padding-top: 100px;
}


.nav-name p {
    margin: 0;
    padding: 0; 
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="radio"]:not(:checked) + span,
[type="radio"]:checked + span {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  transition: .28s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

[type="radio"] + span:before,
[type="radio"] + span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: .28s ease;
  transition: .28s ease;
}

/* Unchecked styles */
[type="radio"]:not(:checked) + span:before,
[type="radio"]:not(:checked) + span:after,
[type="radio"]:checked + span:before,
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border-radius: 50%;
}

[type="radio"]:not(:checked) + span:before,
[type="radio"]:not(:checked) + span:after {
  border: 2px solid #5a5a5a;
}

[type="radio"]:not(:checked) + span:after {
  -webkit-transform: scale(0);
          transform: scale(0);
}

/* Checked styles */
[type="radio"]:checked + span:before {
  border: 2px solid transparent;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #26a69a;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #26a69a;
}

[type="radio"]:checked + span:after {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.user-item:hover{
    background-color: rgba(0,0,0,.1);
    cursor: pointer;
}

.lesson-line__order {
    width: 75px;
}

.lesson-line__title {
    width: 120px;
    margin-right: 15px;
}

.lesson-line {
    display: flex;
    justify-content: flex-start;

}

.task-tittle{
    font-size: 24px;
}

.add-user-btn {
    display: flex;
    position: absolute;
    bottom: 50%;
    right: 60px;
    margin-bottom: -16px;
}

.lesson__task {
    width: 60%;
}

.lesson__page {
    background-image: url(https://media3.giphy.com/media/J1Qp5WXgzdG6eZrxHn/giphy.gif?cid=ecf05e47505tb53lwlm0b3hplzzke9egqic6ibxyaydbzypg&rid=giphy.gif);
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: right 100px;
}

.task__add-user-btn {
    width: 50px;
    display: flex;
    position: absolute;
    bottom: 17px;
    right: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.file_download-btn {
    margin-right: 150px;
}



.btn-wrap {
    display: flex;
    align-items: center;
}

.user-item {
    position: relative;
}

.user-link {
    min-height: 50px;
    display: flex;
    justify-content: center;
    color: rgba(0,0,0,0.87);
}

.red {
    background-color: #FF390F;
}

.task-aria {
    width: 250px;
}

.task-item {
    position: relative;
}

.group-creating {
    font-size: 12px!important;
}

.task-line__order {
    width: 150px!important;
}

.task-line__title {
    width: 90px!important;
}

.task_element {
    width: 80%!important;
}


h1,h2,h3,p {
    margin: 0;
    padding: 0;
}

.group-collection {
    background-color: #fff;

    min-height: 200px;
}

.group__loading {
    position: relative;
    left: 420px;
}

.headline--shadow {
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 30px;
    padding: 5px 30px;
}

.page-headline {
    width: fit-content;
    font-weight: 800;

    font-size: 42px;
    margin-bottom: 45px;

}

.section__headline{
    font-size: 32px;
    font-weight: 800;
}

.page-block__image {
    margin-top: 35px;
    width: 100px;
    margin-bottom: 0;
}

.page-block__image img {
    width: 100%;
    height: auto;
}

.add-shedule {
    align-items: center;
    display: flex;
}

#shadule_new_date {
    width: 100px;
}



.main-page_wrap {
    position: relative;
    
}

.links-item {
    margin-right: 10px;
}

#root {
    position: relative;
}

.updater-wrap{
    background-color: #eee;
}

.updater-list{
    display: flex;
    justify-content: space-between;
}

.users-wrap {
    padding: 15px;
    border: 2px solid #747474;
    padding-top: 0;
    margin-bottom: 15px;
}

.user-list {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
}

.user-list__item {
    align-self: flex-start;
    width: 300px;
}

.img-wrap {
    margin-top: 15px;
}

.instr-block {
    border-bottom: 2px solid #e5e5e5;
    padding-bottom: 20px;
}

.task-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-line__image {
    width: 250px;
    cursor: pointer;
}

.big-image {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.3);
}

.task-line__image.big-image img{
    width: 250px;
    height: auto;
    transform: scale(2);
}

.task-line__image img {
    width: 100%;
    height: auto;
    max-height: 100vh;
}

input[type=file]{
position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
}

.visitors-wrap {
    margin: 0;
    padding: 0;
    width: 42px;
    padding-left: 7px;
}

.shedule-line {
    display: flex;
}

.date-item {
    display: flex;
    align-items: center;
    width: 42px;
    font-weight: bold;
}

.shedule-line h5 {
    width: 300px;
}

.file-upload {
    cursor: pointer;
}

.visitors-wrap__hours{
    text-align: center;
    width: 18px;
}

.users-wrap__hours {
    padding-left: 300px;
}

.visitors-wrap__hours{
    display: none;
    height: 20px;
}
#shadule_new_hours {
    width: 50px;
    margin-left: 5px;
}

.user-list:last-child .visitors-wrap__hours{
    display: block;
}

.exercise_btn {
    margin-top: 15px;
    margin-bottom: 15px;
}

.exercise--complite {
    padding: 20px;
    border-radius: 20px;
    background-color: #02b241;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.vlesson {
    text-align: center;
}



.vlesson-link {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    height: 100%;
    padding: 20px;
    padding-left: 45px;
    color: rgba(0,0,0,0.87);;
}

.collection-item {
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
}


label>input[type=radio] {
    background-color: black;
}

input[type=radio]::before {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid black;
    border-radius: 50%;
}

.user-item.vlesson-item {
    font-size: 18px;
    font-weight: 500;
    padding: 0;
    border-radius: 10px;
}

.collection {
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    
}



.lesson--complite {
    margin-right: 10px;
    color:#fff;
    border-radius: 50%;
    background-color: rgb(55, 187, 31);
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10px;
    font-size: 14px;
    top: 50%;
    margin-top: -12.5px;
}

.misstake{
    position: relative;
}

.misstake::before{
    content: "Ошибка! Попробуйте ещё раз 😉";
    color: red;
    position: absolute;
    top: -20px;
    left: 1px;
}

.variant_text span {
    color: rgb(24, 24, 24);
}

.variants_true .variant_text span {
    color: #757575;
}

label.truth_var span {
    color: rgb(255, 255, 255)!important;
    background-color: rgb(35, 243, 76);
    padding-right: 10px;
    border-radius: 10px;

}

label.truth_var span::before{
    content: "✔";
    border: none !important;
    top:-5px;
    left: 3px;
}

label.truth_var span::after{
    border: none !important;
    background-color: transparent !important;
}

.updater-list{
    display: none;
}

.group-corr_open .updater-list{
    display: flex;
}

.file_label {
    font-size: 10px;
    width: 75px;
    overflow: hidden;
    height: 12px;
}

button:disabled{
    background-color: grey;
}

.exercise-hint {
    font-family: "Roboto" ,Arial, sans-serif;
    color: grey;
    font-size: 14px;
}

.section__header {
    font-family: 'Press Start 2P', Arial,sans-serif;
    font-size: 28px;
    margin-bottom: 35px;
    margin-top: 60px;
}

.vlesson__title{
    font-size: 18px;
    text-transform: capitalize;
    color:#16a7e1;
}

.arrow_img{
    width: 16px;
    top:50%;
    margin-top: calc(16px / 2 *-1);
    transform: rotate(180deg);
    position: absolute;
    left: 15px;
}

.vlessons-list {
    padding-bottom: 25px;
}

.vlesson-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vlesson-row:hover {
    background-color: rgb(206, 242, 253);
}

.vlesson-menu__headline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vlesson-menu__btn {
    margin-top: 15px;
}

p.homing-small-blue-text {
    color: #162ae1;
    font-size: 16px;
    text-align: center;
    margin-top: 7px;
    cursor: pointer;
}

p.homing-small-blue-text:hover {
    opacity: .5;
}


.loading-container {
    position: relative;
}


@media screen and (max-width: 500px){
    .main-page__block {
        width: 300px;
        font-size: 12px;
        min-height: 200px;
    }

    .price-page__decoration-line {
        width: 300px;
    }

    .main-page__headline  {
        
        font-size: 22px!important;
        margin-bottom: 26px;
    }

    .vlesson {
        padding-top: 24px;
    }

    .page-headline{
        font-size: 28px;
    }

    .add-shedule {
        flex-wrap: wrap;
    }

    .add-user-btn {
        flex-direction: column;
        width: 35px;
        font-size: 12px;
        margin-bottom: 0;
        bottom: 0px;
        right: 10px;
    }
    .add-user-btn button,
    .add-user-btn a{
        height: 20px;
        font-size: 8px;
        padding: 0;
        margin-bottom: 5px;
        line-height: 1;
    }

    .add-user-btn a b {
        font-size: 16px;
    }

    .lesson-line {
        flex-direction: column;
        min-height: 75px;
        justify-content: space-between;
    }

    .task-tittle{
        font-size: 16px;
    }

    .lesson__task,
    .lesson-line__title {
        width: auto;
    }

    .group-corr_open .updater-list {
        flex-direction: column;
    }

    .users-wrap {
        display: none;
    }

    .input-field {
        margin: 0;
    }
    
    .updater-wrap{
        background-color: transparent;
    }
}

