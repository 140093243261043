.main-page__right-bg {
    position: absolute;
    z-index: 3;
    width: 50%;
    min-height: 100vh;
    height: 100%;
    left: 0;
    top: 0;

    background: #16A7E1;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 30px 30px 0px;
}

.main-page__cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}

.cards-grid__item {
    box-sizing: border-box;
    width: 500px;
    min-height: 250px;
    margin-bottom: 20px;

    background: #FFFFFF;
    color: #000000;
    border-radius: 20px;
    position: relative;
    z-index: 5;
    padding: 32px 35px 15px 35px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1100px){
    .cards-grid__item {
        box-sizing: border-box;
        width: 420px;
        min-height: 230px;

        padding: 22px 25px 15px 25px;
    
        box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    }

}


@media screen and (max-width: 420px){
    
    .cards-grid__item {
        width: 280px;
        min-height: 230px;

        padding: 22px 25px 15px 25px;
    
        box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    }

    .grid-item__p {
        margin-bottom: 15px;
    }

    .cards-grid__item[href="/turtle"]{
        display: none;
    }
    

}

.cards-grid__item:hover {
    transform: scale(1.01);
}


.main-page__header, .pattern-bg {
    background-image: url("../img/bg-pattern.jpg");
    background-size: 250px;
    min-height: calc(100vh - 110px);
}
.main-page__header {
    display: flex;
    align-items: center;
}

.grid-item__h3 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 32px;
}

.grid-item__p {
    font-size: 16px;
    line-height: 21px;
    width: 95%;
}

.grid-item__button {
    width: 187px;
    height: 43px;
    margin-top: auto;
    
    background: #F9A41F;
    border-radius: 50px;
    
    color: #FFFFFF;
    
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
}

.blue-card {
    background: #16A7E1;
    color: #FFFFFF;
}


.main-page__block {
    position: relative;
    z-index: 10;
    padding: 25px;
    
    margin: 0;
    width: 450px;
    min-height: 400px;
    background-color: #fff;
    /* border: 2px solid #747474; */
    border-radius: 10px;
    
    
    grid-column-start: 1;
    grid-column-end: 2;
    
    grid-row-start: 1;
    grid-row-end: 3;
    justify-self: left;
}

.main-page__desc {
    margin-top: 35px;
    font-size: 18px;
    color: #747474;
}

.main-page__headline {
    /* font-family: 'Press Start 2P', Arial,sans-serif; */
    font-weight: 800;
    font-size: 32px;
}

.reg-state {
    background-color: transparent;
    border: 0;
    outline: 0;
}

@media screen and (max-width: 960px){
    .main-page__cards-grid {
        grid-template-columns: 1fr;

    }
    .nav__bg-elem{
        width: 220px;
    }
    .main-page__right-bg {
        display: none;
    }

    .grid-item__p {
        font-size: 16px;
    }

    .grid-item__button {
        font-size: 16px;
        width: 160px;
        height: 30px;
    }

    .main-page__header {
        padding-top: 25px;
    }
}

@media screen and (max-width: 600px){
    .cards-grid__item[href="/turtle"]{
        display: none;
    }

    .auth-modal {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
        width: initial;
        justify-self: center;
        margin-bottom: 10px;

    }
    .main-page__block {
        grid-column: 1 / 3;
        grid-row: 3 / 6;

        width: initial;
        box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    }
    
}

@media screen and (max-width: 420px){ 
    .auth-card input, #first_name, #second_name {
        width: 230px;
    }
    .main-page__block { 
        width: 270px;
    }
}