.user-item__row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.user-item__btns {
    display: flex;
    height: 50px;
}

@media screen and (max-width: 600px){
    .user-item__row{
        flex-direction: column;
    }

    .user-item__btns button{
        margin-top: 10px;
        height: 27px;
        line-height: 1;
        font-size: 14px;
    }
}