.groups-line {
    display: flex;
    justify-content: space-between;
    align-items:center;
    color: #16A7E1;
}

.container.pattern-bg {
    padding-bottom: 25px;
}

.groups-line__item {
    width: 250px;
}

.groups-line__item:nth-child(3) {
    width: 300px;
}

.group__without-group {
    box-sizing: border-box;
    padding: 50px;
}

.without-group__headline {
    margin-bottom: 25px;
}

.without-group__soon {
    margin-bottom: 30px;
    width: 300px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
}

.without-come-to-us {
    margin-bottom: 25px;
    width: 300px;
}

button.without-btn {
    padding: 10px 40px;
}

@media screen and (max-width: 800px){
    .groups-line{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 420px){
    .groups-line__item {
        width: 100%;
    }

    .groups-line .input-field{
        width: 100%;
    }
    
    .group-create__btn-container
    {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}