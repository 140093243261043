.goback_container {
    padding-left: 35px;
}

.vlesson__exercise {
    border: 1px solid #b3b3b3;
    padding: 15px;
    padding-left: 25px;
    border-radius: 10px;
    text-align: left;
    margin-top: 35px;
}

.btn_true {
    display: none;
}


.exercise__headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}


.exercise__question{
    font-size: 18px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
}

.back_btn {
    margin-top: 25px;
    width: 170px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    color: #fff;
    cursor: pointer;
}

.back_btn:hover{
    background: rgb(97, 160, 255);
}

.side-back__btn {
    min-height: calc(100vh - 64px);
    z-index: 1;
    height: calc(100% - 64px);
    width: 45px;
    background: rgba(97, 160, 255,0.3);
    position: absolute;
    left: 0;
    top: 64px;
    cursor: pointer;
    transform: rotate(180deg);
}

.side-back__btn:hover {
    background: rgba(97, 160, 255,0.6);
}

.vlesson__iframe {
    border-radius: 10px;
}

.img-container img{
    border-radius: 10px;
    width: 300px;
}

.image_variant {
    display: table !important;
    padding-top: 10px;
}

.img_variant_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.vlessons__help-link{
    margin-top: 25px;
}

.vlessons__help-link a{

    margin: 0 auto;
    display: block;
    padding: 10px;
    background-color: rgb(65, 187, 228);
    border-radius: 20px;
    width: 300px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 960px){
    .side-back__btn{
        width: 30px;
    }
    .side-back__btn .arrow_img{
        left: 7px;
    }
}

@media screen and (max-width: 600px){
    .side-back__btn{
        display: none;
    }

    .vlesson__iframe{
        width: 100%;
    }

    .exercise-hint__container{
        display: none;
    }

    .vlesson-link .vlesson__title {
        font-size: 14px;
    }

    .img_variant_grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 450px){
    .image_variant .img-container {
        width: 300px;
    }
}
