@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Light.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Regular.ttf');
    font-weight: 400;
}