.main-navigation {
    height: 110px;    
}

.main_nav__list li {
    float: none;
}

.nav__bg-elem {
    position: absolute;
    z-index: 5;
    width: 300px;
    height: 70px;
    left: 0px;
    top: 21px;

    background: #16A7E1;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 30px 30px 0px;
}

.header-logo {
    position: absolute;
    z-index: 5;
    right: 50px;
    top: 15px;
}


nav {
    width: 100%;
}

.main_nav__container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.main_nav__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.main_nav__list li a:hover{
    background-color: rgba(255, 255, 255, 0.371);
}

.main-nav__burger{
    position: relative;
    height: 26px;
    display: none;
}
.burger-slice {
    width: 35px;
    height: 3px;
    background-color: #fff;
}

.burger-slice::before {
    content: "";
    width: 35px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 10px;
}

.burger-slice::after {
    content: "";
    width: 35px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 20px;
}

.navbar-btn{
    width: 100%;
    cursor: pointer;

    font-weight: 800;
    font-size: 14px;
}

button.navbar-btn {
    color: #000;
    background-color: transparent;
    border: none;
    padding: 22px 15px;
}

.acitve_mobile{
    display: flex!important;
}

.main_nav__list li {
    margin-right: 20px;
}

.main_nav__list li a{
    color: #000;
    text-decoration: none;
    font-weight: 800;
}

.nav-name {
    font-size: 16px;
    padding: 2px 25px;
    font-weight: 800;

    background: #F9A41F;
    border-radius: 50px;

    color: #FFFFFF;
    
    display: flex;
    align-items: center;
    justify-content: center;

    
}

@media screen and (max-width: 800px){
    nav.main-navigation{
        height: 70px;
        background-color: #16A7E1;
    }
    .main_nav__container {
        flex-wrap: wrap;
    }
    .main_nav__list{
        order: 3;
        display: none;
        background-color: #16A7E1;
        z-index: 100;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 62px;
        width: 100%;
        
    }
    .main_nav__list li{
        display: block;
        text-align: center;
    }

    .main-nav__burger {
        display: block;
    }

    .nav-name {
        position: absolute;
        top: -65px;
        left: 42%;
        font-size: 24px;
        padding: 5px 10px;
    
        background: #F9A41F;
        border-radius: 50px;
    
        color: #FFFFFF;
        
        display: flex;
        align-items: center;
        justify-content: center;

        
    }
}

@media screen and (max-width: 600px){
    .main_nav__list{
        top: 70px;
    }

    .main_nav__list li a{
        color: #FFFFFF;
        padding: 10px;

    }

    button.navbar-btn{
        color: #fff;
    }

    .nav-list__el {
        padding: 10px;
    }

    .nav-name {
        position: absolute;
        top: -55px;
        left: 35%;
        font-size: 24px;
    }
}