.achivment-popup {
    position: fixed;
    width: 440px;
    min-height: 510px;
    left: 50%;
    margin-left: -220px;
    top: 50%;
    margin-top: -255px;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    animation-name: bounce;
    animation-duration: 0.6s;
}

.achivment-wrap {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url('/img/confety.png');
    background-position: center 30%;
    background-size: auto 180px;
    background-repeat: no-repeat;
}

.achivment__image {
    width: 120px;
    margin-bottom: 15px;
}

.new-achiv-alert{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px;

    color: #000000;
}

.achivment__title {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 38px;
    text-transform: uppercase;
}

.achivment__description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 30px;
}

.achivment__btn {
    width: 270px;
    height: 52px;
    margin-bottom: 23px;

    background: #16A7E1;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.achivment__seemore {
    width: 243px;

    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #000000;
}

.light-image {
    width: 30px;
    align-self: start;
}

.new-achiv-alert__container {
    display: flex;  
}

@keyframes bounce {
    0% { transform: translateY(-2000px); }
    70% { transform: translateY(30px); }
    90% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

