.course__container {
    width: 85%;
    margin: 0 auto;
}

.course__item--disabled {
 opacity: .5;
}

.course-elem__container {
    position: relative;
    width: fit-content;
    background-color: #fff;
    margin-bottom: 10px;
}

.course__get-button {
    cursor: pointer;
    padding: 8px 34px;
    background-color: #ff390f;
    font-size: 24px;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    width: fit-content;
    position: absolute;
    top:50%;
    margin-top: -22px;
    left: 50%;
    margin-left: -90px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.course__get-button:hover {
    transform: scale(1.01);
}

.course__item {
    width: 390px;
    min-height: 200px;
    background-position: 0px -40px;
    background-size: cover;
    position: relative;
}

.course-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.course__item:not(.course__item--disabled):hover {
    cursor: pointer;
    transform: scale(1.002, 1.002);
    opacity: .6;
}

.course_name {
    margin: 0;
    padding: 0;
    line-height: 30px;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: bold;
    font-size: 28px;
    color: #fff;
}

.course_programm {
    margin: 0;
    padding: 0;
    
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 200;
    font-size: 14px;
    color: #fff;
}

.skills-list {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    min-height: 78px;
    display: flex;
    flex-direction: column;
}

.description_container {
    margin: 0;
    padding: 0;
    padding-left: 25px;
}

.course_age {
    position: absolute;
    font-size: 75px;
    color: rgba(255, 255, 255, 0.487);
    font-weight: bold;
    top: 55px;
    left: 155px;
    line-height: 1;
}

.skills-list__item {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: bold;
    background-color: #fff;
    color: #000;
    font-size: 12px;
    border-radius: 15px;
    align-self: baseline;
    padding: 0px 10px;
    margin-bottom: 5px;
}

.course-inner__description {
    padding: 25px 0;
    font-family: 'Roboto';
    font-size: 16px;
    width: 80%;
    font-weight: 500;
    line-height: 24px;
}

.course-headline{
    display: flex;
    align-items: center;
}

.course-headline img{
    width: 140px;
    height: auto;
    margin-right: 15px;
}

.course-headline .page-headline {
    margin: 0;
}

.course__awaiting {
    background:rgb(17, 158, 240);
    left: 140px;
    box-shadow: none;
    width: 210px;
    font-size: 20px;
    top: 70px;
}

.course__add-user-btn {
    position: absolute;
    top: 0px;
    z-index: 5;
}

.add-course__user-line {
    height: 70px;
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e3e3e3;
}


@media screen and (max-width: 1200px){
    .course-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px){
    .course-list {
        grid-template-columns: 1fr;
    }
    .course__container {
        width: fit-content;
    }
    .nav__bg-elem {
        box-shadow: none;
        height: 70px;
        top: -2px;
        left: -60px;
        /* display: none; */
    }
}

@media screen and (max-width: 460px){
    .course__item {
        width: 288px;
        height: 180px;
        background-position:0 -21px;
        background-size: 288px 205px;
        background-repeat: no-repeat;
    }

    .course__awaiting {
        left: 32%;
    }
    
    .skills-list{
        padding-top: 20px;
        min-height: 80px;
    }
    
    .course_age {
        font-size: 48px;
    }

    .course_programm {
        line-height: 1;
    }

    .course_name {
        line-height: 22px;
        font-size: 20px;
    }
}

