.order__popup {
    position: fixed;
    width: 534px;
    min-height: 630px;
    margin-top: -315px;
    padding-bottom: 15px;
    left: 50%;
    margin-left: -262px;
    box-sizing: border-box;
    padding-top: 40px;
    text-align: center;
    top: 50%;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;

    animation-name: bounce;
    animation-duration: 0.6s;
}

.order__headline {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 30px;
}

.order__description {
    width: 400px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 30px;
}

.order__popup label {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 25px;
}

#order-phone {
    width: 300px;
    border: none;
    outline: none;
    border-bottom: 2px solid #16A7E1;
    text-align: center;
    padding-bottom: 5px;
    font-size: 20px;
    margin-bottom: 25px;
}

#order-age {
    width: 70px;
    border: none;
    outline: none;
    border-bottom: 2px solid #16A7E1;
    text-align: center;
    padding-bottom: 5px;
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center;
    
}

label[for=order-program]{
    width: 390px;
}

#order-program{
    width: 320px;
    border: 2px solid #F9B233;
    border-radius: 10px;
    margin-bottom: 30px;
    min-height: 30px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .order__popup {
        width: 380px;
        margin-left: -190px;
        top: 0;
        margin-top: 0;
    }
    
    .order__description {
        width: 330px;
    }
    label[for=order-program]{
        width: 330px;
    }
}


