.transaction-list__item {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e3e3e3;
    width: fit-content;
}

.trans-list-item__order-id {
    width: 170px;
}

.trans-list-item__order-date {
    width: 210px;
}

.trans-list-item__order-user {
    width: 300px;
    margin-right: 20px;
}

.trans-list-item__order-sum {
    font-weight: 600;
    width: 100px;
}

.table-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    border: none;
}