.turtle-page__description {
    width: 410px;

    font-family: 'Roboto', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #fff;
}

.turtle-page__description {
    margin-bottom: 15px;
}

.turtle-page__icon {
    width: 30px;
    margin-right: 10px;
}

.turtle-page__icon-desc {
    font-size: 16px;
}

.turtle-page__work-desc {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.turtle-page__work-desc-list {
    margin-bottom: 35px;
}


.turtle-page-wrap {
    display: flex;
    justify-content: space-between;
}

.turtle-left {
    width: 80%;
}

.dragover-el {
    border: 2px dotted rgb(61, 61, 61);
}

.turtle-page__lessons-link {
    width: 152px;
    min-height: 531px;

    background: #16A7E1;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 30px;

    margin-top: -46px;
}

.lessons-link__list li a
{
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    display: block;
    padding: 5px 25px;

}

.lessons-link__list li {

    margin-bottom: 20px;
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;

}

.lessons-link__current {
    
    border-radius: 30px;
    background-color: #F9A41F;
}

.lessons-link__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.turtle-example {
    padding-top: 70px;
    width: 400px;
}

.turtle-example img{
    width: 100%;
}

.turtle-example__desc {
    padding: 20px 0;
}

.turtle-page__intro {
    display: flex;
    justify-content: space-between;
}

.podlozhka {
    position: absolute;
    width: 100%;
    top: 100px;
    left: 0px;
    z-index: -1;
}

.turtle-page__wrap {
    background-color: #fff;
    padding-left: 35px;
}

.turtle--complite {
    position: relative;
}

.turtle--complite::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100px;
    background: #fff;
    top: 13px;
    left: 15px;
}

.turte-page__desc-wrap {
    background-color: #16A7E1;
    padding: 20px 20px;
    border-radius: 20px;
    color: #fff;
    margin-top: 20px;
}
