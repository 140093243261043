@keyframes ldio-p5ouf7axk5-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-p5ouf7axk5-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-p5ouf7axk5 > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-p5ouf7axk5 > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #16a7e1;
  animation: ldio-p5ouf7axk5-1 1s linear infinite;
  transform-origin: 60px 60px
}
.ldio-p5ouf7axk5 > div:nth-child(2) div:nth-child(2) {
  animation: ldio-p5ouf7axk5-2 1s linear infinite
}
.ldio-p5ouf7axk5 > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-p5ouf7axk5-3 {
    0% { transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(70px,0); opacity: 1 }
}
.ldio-p5ouf7axk5 > div:nth-child(1) {
  display: block;
}
.ldio-p5ouf7axk5 > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff390f;
  animation: ldio-p5ouf7axk5-3 1s linear infinite
}
.ldio-p5ouf7axk5 > div:nth-child(1) div:nth-child(1) { animation-delay: -0.67s }
.ldio-p5ouf7axk5 > div:nth-child(1) div:nth-child(2) { animation-delay: -0.33s }
.ldio-p5ouf7axk5 > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-prltkzhz4qp {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-p5ouf7axk5 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-p5ouf7axk5 div { box-sizing: content-box; }

.loading__container{
    position: absolute;
    top: 0;
    text-align: center;
    transform: scale(0.5);
}

@media screen and (max-width: 420px){
  .loading__container {
    position: absolute;
  }
}