@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&display=swap);
@-webkit-keyframes ldio-p5ouf7axk5-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-p5ouf7axk5-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@-webkit-keyframes ldio-p5ouf7axk5-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
@keyframes ldio-p5ouf7axk5-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-p5ouf7axk5 > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-p5ouf7axk5 > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #16a7e1;
  -webkit-animation: ldio-p5ouf7axk5-1 1s linear infinite;
          animation: ldio-p5ouf7axk5-1 1s linear infinite;
  transform-origin: 60px 60px
}
.ldio-p5ouf7axk5 > div:nth-child(2) div:nth-child(2) {
  -webkit-animation: ldio-p5ouf7axk5-2 1s linear infinite;
          animation: ldio-p5ouf7axk5-2 1s linear infinite
}
.ldio-p5ouf7axk5 > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  -webkit-animation: none;
          animation: none;
}@-webkit-keyframes ldio-p5ouf7axk5-3 {
    0% { transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(70px,0); opacity: 1 }
}@keyframes ldio-p5ouf7axk5-3 {
    0% { transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(70px,0); opacity: 1 }
}
.ldio-p5ouf7axk5 > div:nth-child(1) {
  display: block;
}
.ldio-p5ouf7axk5 > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff390f;
  -webkit-animation: ldio-p5ouf7axk5-3 1s linear infinite;
          animation: ldio-p5ouf7axk5-3 1s linear infinite
}
.ldio-p5ouf7axk5 > div:nth-child(1) div:nth-child(1) { -webkit-animation-delay: -0.67s; animation-delay: -0.67s }
.ldio-p5ouf7axk5 > div:nth-child(1) div:nth-child(2) { -webkit-animation-delay: -0.33s; animation-delay: -0.33s }
.ldio-p5ouf7axk5 > div:nth-child(1) div:nth-child(3) { -webkit-animation-delay: 0s; animation-delay: 0s }
.loadingio-spinner-bean-eater-prltkzhz4qp {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-p5ouf7axk5 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-p5ouf7axk5 div { box-sizing: content-box; }

.loading__container{
    position: absolute;
    top: 0;
    text-align: center;
    transform: scale(0.5);
}

@media screen and (max-width: 420px){
  .loading__container {
    position: absolute;
  }
}
.goback_container {
    padding-left: 35px;
}

.vlesson__exercise {
    border: 1px solid #b3b3b3;
    padding: 15px;
    padding-left: 25px;
    border-radius: 10px;
    text-align: left;
    margin-top: 35px;
}

.btn_true {
    display: none;
}


.exercise__headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}


.exercise__question{
    font-size: 18px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
}

.back_btn {
    margin-top: 25px;
    width: 170px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    color: #fff;
    cursor: pointer;
}

.back_btn:hover{
    background: rgb(97, 160, 255);
}

.side-back__btn {
    min-height: calc(100vh - 64px);
    z-index: 1;
    height: calc(100% - 64px);
    width: 45px;
    background: rgba(97, 160, 255,0.3);
    position: absolute;
    left: 0;
    top: 64px;
    cursor: pointer;
    transform: rotate(180deg);
}

.side-back__btn:hover {
    background: rgba(97, 160, 255,0.6);
}

.vlesson__iframe {
    border-radius: 10px;
}

.img-container img{
    border-radius: 10px;
    width: 300px;
}

.image_variant {
    display: table !important;
    padding-top: 10px;
}

.img_variant_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.vlessons__help-link{
    margin-top: 25px;
}

.vlessons__help-link a{

    margin: 0 auto;
    display: block;
    padding: 10px;
    background-color: rgb(65, 187, 228);
    border-radius: 20px;
    width: 300px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 960px){
    .side-back__btn{
        width: 30px;
    }
    .side-back__btn .arrow_img{
        left: 7px;
    }
}

@media screen and (max-width: 600px){
    .side-back__btn{
        display: none;
    }

    .vlesson__iframe{
        width: 100%;
    }

    .exercise-hint__container{
        display: none;
    }

    .vlesson-link .vlesson__title {
        font-size: 14px;
    }

    .img_variant_grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 450px){
    .image_variant .img-container {
        width: 300px;
    }
}

.main-navigation {
    height: 110px;    
}

.main_nav__list li {
    float: none;
}

.nav__bg-elem {
    position: absolute;
    z-index: 5;
    width: 300px;
    height: 70px;
    left: 0px;
    top: 21px;

    background: #16A7E1;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 30px 30px 0px;
}

.header-logo {
    position: absolute;
    z-index: 5;
    right: 50px;
    top: 15px;
}


nav {
    width: 100%;
}

.main_nav__container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.main_nav__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.main_nav__list li a:hover{
    background-color: rgba(255, 255, 255, 0.371);
}

.main-nav__burger{
    position: relative;
    height: 26px;
    display: none;
}
.burger-slice {
    width: 35px;
    height: 3px;
    background-color: #fff;
}

.burger-slice::before {
    content: "";
    width: 35px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 10px;
}

.burger-slice::after {
    content: "";
    width: 35px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 20px;
}

.navbar-btn{
    width: 100%;
    cursor: pointer;

    font-weight: 800;
    font-size: 14px;
}

button.navbar-btn {
    color: #000;
    background-color: transparent;
    border: none;
    padding: 22px 15px;
}

.acitve_mobile{
    display: flex!important;
}

.main_nav__list li {
    margin-right: 20px;
}

.main_nav__list li a{
    color: #000;
    text-decoration: none;
    font-weight: 800;
}

.nav-name {
    font-size: 16px;
    padding: 2px 25px;
    font-weight: 800;

    background: #F9A41F;
    border-radius: 50px;

    color: #FFFFFF;
    
    display: flex;
    align-items: center;
    justify-content: center;

    
}

@media screen and (max-width: 800px){
    nav.main-navigation{
        height: 70px;
        background-color: #16A7E1;
    }
    .main_nav__container {
        flex-wrap: wrap;
    }
    .main_nav__list{
        order: 3;
        display: none;
        background-color: #16A7E1;
        z-index: 100;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 62px;
        width: 100%;
        
    }
    .main_nav__list li{
        display: block;
        text-align: center;
    }

    .main-nav__burger {
        display: block;
    }

    .nav-name {
        position: absolute;
        top: -65px;
        left: 42%;
        font-size: 24px;
        padding: 5px 10px;
    
        background: #F9A41F;
        border-radius: 50px;
    
        color: #FFFFFF;
        
        display: flex;
        align-items: center;
        justify-content: center;

        
    }
}

@media screen and (max-width: 600px){
    .main_nav__list{
        top: 70px;
    }

    .main_nav__list li a{
        color: #FFFFFF;
        padding: 10px;

    }

    button.navbar-btn{
        color: #fff;
    }

    .nav-list__el {
        padding: 10px;
    }

    .nav-name {
        position: absolute;
        top: -55px;
        left: 35%;
        font-size: 24px;
    }
}
/* .auth-modal {
    position: absolute;
    top: 57px;
    
    width: 450px;
    right: 15%;
} */


.auth-modal {
    box-sizing: border-box;
    padding: 30px;
    width: 400px;
    min-height: 371px;

    background: #16A7E1;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    justify-self: right;
    align-self: center;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.auth-card{
    min-height: 371px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.card-title {
    font-weight: 800;
    font-size: 24px;
    color: #fff;
}

.auth-card input, #first_name, #second_name{
    padding: 2px 15px;
    color: #000;
    border: 2px solid #e3e3e3;
    border-radius: 30px;
    width: 300px;
    height: 30px;
    outline: 0;
    background-color: #fff;
    /* text-align: center; */
}

.auth_name-input {
    margin-bottom: 20px;
}

.auth__enter-btn{
    cursor: pointer;    
    font-size: 16px;
    padding: 2px 25px;
    font-weight: 800;
    background: #F9A41F;
    border: 0;
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth__reg-btn {
    cursor: pointer;
    font-size: 16px;
    padding: 2px 25px;
    font-weight: 800;
    background: #ff390f;
    border: 0;
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth__back-to-enter-btn {
    font-size: 14px;
    font-weight: 800;
    color: rgb(22, 0, 162);
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(22, 0, 162);
    padding: 0;
}

.auth-page__buttons {
    display: flex;
    justify-content: space-between;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

#email,
#password{
    /* border-bottom: 1px solid #fff; */
}
#email.valid,
#password.valid,
#email:focus,
#password:focus
{
    border-bottom: 1px solid #26a69a;
}

#email.invalid,
#password.invalid
{
    border-color: #F44336;
}

@media screen and (max-width: 650px){
    .auth-modal {
        right: 0;
        width: 100%;
        top: 48px;
    }
}



.groups-line {
    display: flex;
    justify-content: space-between;
    align-items:center;
    color: #16A7E1;
}

.container.pattern-bg {
    padding-bottom: 25px;
}

.groups-line__item {
    width: 250px;
}

.groups-line__item:nth-child(3) {
    width: 300px;
}

.group__without-group {
    box-sizing: border-box;
    padding: 50px;
}

.without-group__headline {
    margin-bottom: 25px;
}

.without-group__soon {
    margin-bottom: 30px;
    width: 300px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
}

.without-come-to-us {
    margin-bottom: 25px;
    width: 300px;
}

button.without-btn {
    padding: 10px 40px;
}

@media screen and (max-width: 800px){
    .groups-line{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 420px){
    .groups-line__item {
        width: 100%;
    }

    .groups-line .input-field{
        width: 100%;
    }
    
    .group-create__btn-container
    {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}
.user-item__row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.user-item__btns {
    display: flex;
    height: 50px;
}

@media screen and (max-width: 600px){
    .user-item__row{
        flex-direction: column;
    }

    .user-item__btns button{
        margin-top: 10px;
        height: 27px;
        line-height: 1;
        font-size: 14px;
    }
}
.course__container {
    width: 85%;
    margin: 0 auto;
}

.course__item--disabled {
 opacity: .5;
}

.course-elem__container {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #fff;
    margin-bottom: 10px;
}

.course__get-button {
    cursor: pointer;
    padding: 8px 34px;
    background-color: #ff390f;
    font-size: 24px;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top:50%;
    margin-top: -22px;
    left: 50%;
    margin-left: -90px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.course__get-button:hover {
    transform: scale(1.01);
}

.course__item {
    width: 390px;
    min-height: 200px;
    background-position: 0px -40px;
    background-size: cover;
    position: relative;
}

.course-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.course__item:not(.course__item--disabled):hover {
    cursor: pointer;
    transform: scale(1.002, 1.002);
    opacity: .6;
}

.course_name {
    margin: 0;
    padding: 0;
    line-height: 30px;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: bold;
    font-size: 28px;
    color: #fff;
}

.course_programm {
    margin: 0;
    padding: 0;
    
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 200;
    font-size: 14px;
    color: #fff;
}

.skills-list {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    min-height: 78px;
    display: flex;
    flex-direction: column;
}

.description_container {
    margin: 0;
    padding: 0;
    padding-left: 25px;
}

.course_age {
    position: absolute;
    font-size: 75px;
    color: rgba(255, 255, 255, 0.487);
    font-weight: bold;
    top: 55px;
    left: 155px;
    line-height: 1;
}

.skills-list__item {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: bold;
    background-color: #fff;
    color: #000;
    font-size: 12px;
    border-radius: 15px;
    align-self: baseline;
    padding: 0px 10px;
    margin-bottom: 5px;
}

.course-inner__description {
    padding: 25px 0;
    font-family: 'Roboto';
    font-size: 16px;
    width: 80%;
    font-weight: 500;
    line-height: 24px;
}

.course-headline{
    display: flex;
    align-items: center;
}

.course-headline img{
    width: 140px;
    height: auto;
    margin-right: 15px;
}

.course-headline .page-headline {
    margin: 0;
}

.course__awaiting {
    background:rgb(17, 158, 240);
    left: 140px;
    box-shadow: none;
    width: 210px;
    font-size: 20px;
    top: 70px;
}

.course__add-user-btn {
    position: absolute;
    top: 0px;
    z-index: 5;
}

.add-course__user-line {
    height: 70px;
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e3e3e3;
}


@media screen and (max-width: 1200px){
    .course-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px){
    .course-list {
        grid-template-columns: 1fr;
    }
    .course__container {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .nav__bg-elem {
        box-shadow: none;
        height: 70px;
        top: -2px;
        left: -60px;
        /* display: none; */
    }
}

@media screen and (max-width: 460px){
    .course__item {
        width: 288px;
        height: 180px;
        background-position:0 -21px;
        background-size: 288px 205px;
        background-repeat: no-repeat;
    }

    .course__awaiting {
        left: 32%;
    }
    
    .skills-list{
        padding-top: 20px;
        min-height: 80px;
    }
    
    .course_age {
        font-size: 48px;
    }

    .course_programm {
        line-height: 1;
    }

    .course_name {
        line-height: 22px;
        font-size: 20px;
    }
}


@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Light.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Regular.ttf');
    font-weight: 400;
}

.main-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.main-turtle__wrap {
    display: flex;
}

.comand {
    padding-top: 50px;
}

.main-headline,
.secont-headline {
    font-family: "Neucha", Helvetica, sans-serif;
    color: #fff;
    text-shadow: #444343 3px 3px;
    font-size: 38px;
}

.secont-headline {
    font-size: 28px;
}
.description {
    padding: 10px;
    width: 600px;
    background-color: #fff;
    box-shadow: #7f3f98 10px 10px;
}
.container-turtle {
    display: flex; 
}
#house {
    height: 220px;
    width: 230px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 25px;
    box-shadow: #ffde16 10px 10px;
}
#parent {
    height: 220px;
    width: 270px;
    margin-left: 0;
    box-shadow: #ec008c 10px 10px;
}

.text-hint{
    padding-top: 60px;
    opacity: .6;
    text-align: center;
}

.checher {
    height: 30px;
    width: 30px;
    background-color: rgb(28, 171, 238);
    margin: 5px;  
    background-size: cover;
    background-position: top;
    cursor: pointer;
    box-sizing: border-box;
}
.hint {
    width: 270px;
    background: #fff;
    padding: 10px;
    border: 2px solid #000;
    border-radius: 15px;
    box-shadow: #20409a 10px 10px;
    font-size: 18px;   
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.hint:hover{
    background: rgb(102, 102, 102);
}

.hint__wide{
    width: 500px;
}
.challenge-area {
    margin: 0;
    padding: 0;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 5px;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 15px;
}

.lover-line {
    display: flex;
    align-items: center;
}

.next-ex {
    display: block;
    text-decoration: none;
    font-size: 18px;
    margin-left: 30px;
    padding: 10px;
    background-color: #fff;
    color: #20409a;
    border: 2px solid #20409a;
    font-weight: bold;
}


.none {
    display: none;
}

.false {
    background-color: red;
}
.truth {
    background-color: green;
}

canvas {
    width: 400px;
    height: 400px;
}


#leaves,
#cactus,
#golang {
    height: 0;
    width: 0;
}


/* items list  descriotion*/

.end {
    background-image:url("/img/turtleEx/end.png");
}


.number {
    background-image:url(/img/turtleEx/number.png);
}

.rep {
    background-image:url(/img/turtleEx/rep.png);
}
.do {
    background-image:url(/img/turtleEx/do.png);
}

.fwd {
    background-image:url(/img/turtleEx/fwd.png);
}
.right {
    background-image:url(/img/turtleEx/right.png);
}
.left {
    background-image:url(/img/turtleEx/left.png);
}
.dwn {
    background-image:url(/img/turtleEx/dwn.png);
}
.num1{
    background-image:url(/img/turtleEx/1.png);
}
.num2{
    background-image:url(/img/turtleEx/2.png);
}
.num3{
    background-image:url(/img/turtleEx/3.png);
}
.num4{
    background-image:url(/img/turtleEx/4.png);
}
.num5{
    background-image:url(/img/turtleEx/5.png);
}
.num6{
    background-image:url(/img/turtleEx/6.png);
}

@media screen and (max-width: 1100px){

    #parent,
    #house{
        width: 200px;
    }   
}

@media screen and (max-width: 600px){
    .main-container{
        display: none;
    }

    
}
.main-page__right-bg {
    position: absolute;
    z-index: 3;
    width: 50%;
    min-height: 100vh;
    height: 100%;
    left: 0;
    top: 0;

    background: #16A7E1;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 30px 30px 0px;
}

.main-page__cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}

.cards-grid__item {
    box-sizing: border-box;
    width: 500px;
    min-height: 250px;
    margin-bottom: 20px;

    background: #FFFFFF;
    color: #000000;
    border-radius: 20px;
    position: relative;
    z-index: 5;
    padding: 32px 35px 15px 35px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1100px){
    .cards-grid__item {
        box-sizing: border-box;
        width: 420px;
        min-height: 230px;

        padding: 22px 25px 15px 25px;
    
        box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    }

}


@media screen and (max-width: 420px){
    
    .cards-grid__item {
        width: 280px;
        min-height: 230px;

        padding: 22px 25px 15px 25px;
    
        box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    }

    .grid-item__p {
        margin-bottom: 15px;
    }

    .cards-grid__item[href="/turtle"]{
        display: none;
    }
    

}

.cards-grid__item:hover {
    transform: scale(1.01);
}


.main-page__header, .pattern-bg {
    background-image: url(/static/media/bg-pattern.f2f97980.jpg);
    background-size: 250px;
    min-height: calc(100vh - 110px);
}
.main-page__header {
    display: flex;
    align-items: center;
}

.grid-item__h3 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 32px;
}

.grid-item__p {
    font-size: 16px;
    line-height: 21px;
    width: 95%;
}

.grid-item__button {
    width: 187px;
    height: 43px;
    margin-top: auto;
    
    background: #F9A41F;
    border-radius: 50px;
    
    color: #FFFFFF;
    
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
}

.blue-card {
    background: #16A7E1;
    color: #FFFFFF;
}


.main-page__block {
    position: relative;
    z-index: 10;
    padding: 25px;
    
    margin: 0;
    width: 450px;
    min-height: 400px;
    background-color: #fff;
    /* border: 2px solid #747474; */
    border-radius: 10px;
    
    
    grid-column-start: 1;
    grid-column-end: 2;
    
    grid-row-start: 1;
    grid-row-end: 3;
    justify-self: left;
}

.main-page__desc {
    margin-top: 35px;
    font-size: 18px;
    color: #747474;
}

.main-page__headline {
    /* font-family: 'Press Start 2P', Arial,sans-serif; */
    font-weight: 800;
    font-size: 32px;
}

.reg-state {
    background-color: transparent;
    border: 0;
    outline: 0;
}

@media screen and (max-width: 960px){
    .main-page__cards-grid {
        grid-template-columns: 1fr;

    }
    .nav__bg-elem{
        width: 220px;
    }
    .main-page__right-bg {
        display: none;
    }

    .grid-item__p {
        font-size: 16px;
    }

    .grid-item__button {
        font-size: 16px;
        width: 160px;
        height: 30px;
    }

    .main-page__header {
        padding-top: 25px;
    }
}

@media screen and (max-width: 600px){
    .cards-grid__item[href="/turtle"]{
        display: none;
    }

    .auth-modal {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
        width: auto;
        width: initial;
        justify-self: center;
        margin-bottom: 10px;

    }
    .main-page__block {
        grid-column: 1 / 3;
        grid-row: 3 / 6;

        width: auto;

        width: initial;
        box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    }
    
}

@media screen and (max-width: 420px){ 
    .auth-card input, #first_name, #second_name {
        width: 230px;
    }
    .main-page__block { 
        width: 270px;
    }
}
.price-page__decoration-line {
    margin-top: 10px;
    width: 400px;
    height: 4px;

    background: #16A7E1;
}

.price-page__headline {
    margin-top: 28px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
}

.fast-desc {
    display: flex;
    width: 360px;
    justify-content: space-between; 
    margin-top: 25px;
    margin-bottom: 25px;
}

.fast-desc__item {
    display: flex;
    align-items: center;
}


.desc-item__number {
    font-style: normal;
    font-weight: 900;
    font-size: 46px;
    line-height: 54px;

    color: #000000;
    margin-right: 5px;
}

.desc-item__content {
    width: 118px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
}

.price__plans-line {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plans-line__item {
    position: relative;
    padding: 28px 23px;
    box-sizing: border-box;
    width: 310px;
    min-height: 386px;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
}

.line-item__description-list li{
    position: relative;
}

.line-item__description-list li::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    background-color: #FF390F;
    left: -15px;
    top: 5px;
}

.price-page__disclamer {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-size: 9px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 85px;
}

.line-item__h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;

    color: #000000;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-bottom: 25px;
}

.line-item__price {
    font-weight: 900;
    font-size: 42px;
    line-height: 49px;

    color: #000000;
    margin-bottom: 15px;
}

.price__small-desc {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
}

.line-item__description-list {
    padding-left: 15px;

}

.line-item__description-list li{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;

    color: #000000;
}
.line-item__button{
    cursor: pointer;
    font-size: 24px;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    width: 270px;
    height: 45px;
    padding-top: 10px;

    box-sizing: border-box;
    background: #FF390F;
    border-radius: 30px;

    margin-top: auto;
}

.mentor-item {
    box-shadow: none;
    background-color: transparent;
    color: #fff;
    background-color: #16A7E1;
}

.mentor-item p,
.mentor-item span,
.mentor-item li,
.mentor-item h3
{
    color: #fff;
}

.mentor-item .line-item__description-list li::before {
    background-color: #F9A41F;
}

.mentor-item .line-item__button {
    background-color: #F9A41F;
}

.price-page__top-line {
    display: flex;
}

.top-line__image {
    width: 200px;
}

.top-line__description {
    margin-left: 100px;
    display: flex;
    align-items:flex-start;
}

.top-line__description p{
    padding-top: 10px;
    width: 300px;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-line__description ul{
    padding-left: 15px;
}

.top-line__description li{
    font-size: 14px;
    font-weight: bold;
    position: relative;
}

.top-line__description li::before{
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    background-color: #F9A41F;
    top: 4px;
    left: -10px;
}
.profile-page__headline {
    margin-top: 50px;
    margin-bottom: 17px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
}

.profile-line {
    margin-bottom: 26px;
}

.profile-page__info-grid {
    display: grid;

    margin-bottom: 35px;
}

.info-grid__balance-wrap {
    grid-column-start: 1;
    grid-column-end: 2;

}
.info-grid__status-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
}
.balance-up {
    grid-column: 3 / 4;
    grid-row: 2 / 3;

    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-grid__sub-status {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.info-grid__atandance {
    grid-column: 2 / 3;

}

.subscribe__btn {
    grid-column: 4 / 6;
    grid-row: 1 / 3;
    justify-self: end;

    box-sizing: border-box;
    width: 460px;
    height: auto;
    padding: 6px 38px;
    border: none;

    box-sizing: border-box;
    background: #F9B233;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    cursor: pointer;

}

.subscribe__btn:hover {
    opacity: .8;
}

.info-grid__balance-wrap span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;

    color: #000000;
}

.info-grid__item {
    margin-bottom: 18px;
}

.info-grid__item b {
    display: flex;
    align-items: center;
    width: 80px;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;

    color: #000000;
}

.info-grid__atandance b,
.info-grid__sub-status b {
    width: 125px;
}



.info-grid__item span {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;

    color: #000000;
}

.info-grid__item {
    display: flex;
}


.balance-up {
    box-sizing: border-box;

    margin-top: -10px;
    margin-right: 10px;

    background: #FF390F;
    border-radius: 10px;
    
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    cursor: pointer;

    border: 0;

    outline: none;
}

.balance-up:hover {
    opacity: .8;
}

.subscribe-btn__cta {
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;

    color: #FFFFFF;
}

.subscribe-btn__description{
    font-size: 14px;
    text-align: center;

    color: #FFFFFF;
}

.profile__achievements-grid {
    background: #16A7E1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 45px;
}

.desc__item--white .desc-item__number,
.desc__item--white .desc-item__content
{
    color: #fff;
}

.profile__awards {
    margin-bottom: 45px;
}

.profile-page__h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;

    color: #000000;
    margin-bottom: 35px;
}

.awards-list {
    display: flex;
    flex-wrap: wrap;
}

.awards-list__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 225px;
    margin-right: 50px;
    margin-bottom: 25px;
}

.awards-list__item img{
    grid-row: 1 / 3;
    margin-top: -13px;
    width: 125px;
    height: 125px;
}

.awards-list__item h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;

    color: #000000;

}

.awards-list__item p {
    width: 125px;

    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 16px;

    color: #000000;

    grid-column: 2 / 3;
}

.profile__certificates {
    margin-bottom: 45px;
}

.certificates-list {
    display: flex;
}

.certificates-list__item {
    width: 144px;
    height: 215px;
    background-image: url("https://homing.yescoding.ru/uploads/19012021-131338-alexkem.jpg");
    background-size: cover;
    margin-right: 25px;
}

.activity {
    margin-bottom: 25px;
}

.active-table__item {
    padding-left: 35px;
    display: flex;
    margin-bottom: 15px;
}

.active-table-item__title {
    width: 214px;

    font-weight: 900;
    font-size: 16px;
    line-height: 19px;

    color: #16A7E1;
}

.active-table-item__date {
    width: 150px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.active-table-item__sum{
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.info-grid__pay-field {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.info-grid__pay-field b {
    width: 80px;
    display: flex;
    align-items: center;
}

#amount {
    height: auto;
    width: 83px;
    border-radius: 20px;
    border: 1px solid;
    text-align: center;
}

#amount::-webkit-inner-spin-button {
	display: none;
}

.modal {
    position: fixed;
    width: 600px;
    height: 400px;
    top: 50%;
    margin-top: -300px;
    left: 50%;
    margin-left: -300px;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    box-sizing: border-box;
    padding: 35px 35px;

    border-radius: 20px;
}
.modal-podlozhka {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999;
}

.modal h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 15px;
}

.modal p{
    font-size: 20px;
    margin-bottom: 35px;
}

.modal button,
.modal a{ 
    margin: 0 auto; 
}

.sub-update-modal {
    height: 250px;
    margin-top: -125px;
}

.modal-loading{
    position: absolute;
    top: 10%;
    left: 35%
}

.btn-loader {
    position: relative;
    top: -82px;
    left: 40px;
}


@media screen and (max-width: 600px){
    .active-table-item__date {
        width: 90px;
    }

    .active-table-item__title {
        width: 100px;
    }
    .profile-page__headline {
        padding-top: 15px;
    }
    .info-grid__pay-field{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    .subscribe__btn{
        grid-column: 1 / 3;
        grid-row: 4 / 6;
    }
    
    .balance-up {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }

    .info-grid__item {
        margin-right: 30px;
    }
}

@media screen and (max-width: 450px){
    .info-grid__sub-status{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .info-grid__atandance{
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }

    .info-grid__pay-field{
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }

    .balance-up {
        grid-column: 1 / 2;
        grid-row: 6 / 7;
        margin: 0;
    }

    .subscribe__btn{

        grid-row: 7 / 9;
        width: auto;
        width: initial;
        margin-top: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .info-grid__item b {
        width: 125px;
    }

    .profile__achievements-grid {
        flex-direction: column;
        width: 90%
        
    }

    .desc-item__number {
        width: 82px;
    }
}


.achivment-popup {
    position: fixed;
    width: 440px;
    min-height: 510px;
    left: 50%;
    margin-left: -220px;
    top: 50%;
    margin-top: -255px;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    -webkit-animation-name: bounce;

            animation-name: bounce;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
}

.achivment-wrap {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url('/img/confety.png');
    background-position: center 30%;
    background-size: auto 180px;
    background-repeat: no-repeat;
}

.achivment__image {
    width: 120px;
    margin-bottom: 15px;
}

.new-achiv-alert{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px;

    color: #000000;
}

.achivment__title {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 38px;
    text-transform: uppercase;
}

.achivment__description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 30px;
}

.achivment__btn {
    width: 270px;
    height: 52px;
    margin-bottom: 23px;

    background: #16A7E1;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.achivment__seemore {
    width: 243px;

    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #000000;
}

.light-image {
    width: 30px;
    align-self: start;
}

.new-achiv-alert__container {
    display: flex;  
}

@-webkit-keyframes bounce {
    0% { transform: translateY(-2000px); }
    70% { transform: translateY(30px); }
    90% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

@keyframes bounce {
    0% { transform: translateY(-2000px); }
    70% { transform: translateY(30px); }
    90% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}


.turtle-page__description {
    width: 410px;

    font-family: 'Roboto', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #fff;
}

.turtle-page__description {
    margin-bottom: 15px;
}

.turtle-page__icon {
    width: 30px;
    margin-right: 10px;
}

.turtle-page__icon-desc {
    font-size: 16px;
}

.turtle-page__work-desc {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.turtle-page__work-desc-list {
    margin-bottom: 35px;
}


.turtle-page-wrap {
    display: flex;
    justify-content: space-between;
}

.turtle-left {
    width: 80%;
}

.dragover-el {
    border: 2px dotted rgb(61, 61, 61);
}

.turtle-page__lessons-link {
    width: 152px;
    min-height: 531px;

    background: #16A7E1;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 30px;

    margin-top: -46px;
}

.lessons-link__list li a
{
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    display: block;
    padding: 5px 25px;

}

.lessons-link__list li {

    margin-bottom: 20px;
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;

}

.lessons-link__current {
    
    border-radius: 30px;
    background-color: #F9A41F;
}

.lessons-link__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.turtle-example {
    padding-top: 70px;
    width: 400px;
}

.turtle-example img{
    width: 100%;
}

.turtle-example__desc {
    padding: 20px 0;
}

.turtle-page__intro {
    display: flex;
    justify-content: space-between;
}

.podlozhka {
    position: absolute;
    width: 100%;
    top: 100px;
    left: 0px;
    z-index: -1;
}

.turtle-page__wrap {
    background-color: #fff;
    padding-left: 35px;
}

.turtle--complite {
    position: relative;
}

.turtle--complite::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100px;
    background: #fff;
    top: 13px;
    left: 15px;
}

.turte-page__desc-wrap {
    background-color: #16A7E1;
    padding: 20px 20px;
    border-radius: 20px;
    color: #fff;
    margin-top: 20px;
}

.order__popup {
    position: fixed;
    width: 534px;
    min-height: 630px;
    margin-top: -315px;
    padding-bottom: 15px;
    left: 50%;
    margin-left: -262px;
    box-sizing: border-box;
    padding-top: 40px;
    text-align: center;
    top: 50%;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;

    -webkit-animation-name: bounce;

            animation-name: bounce;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
}

.order__headline {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 30px;
}

.order__description {
    width: 400px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 30px;
}

.order__popup label {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 25px;
}

#order-phone {
    width: 300px;
    border: none;
    outline: none;
    border-bottom: 2px solid #16A7E1;
    text-align: center;
    padding-bottom: 5px;
    font-size: 20px;
    margin-bottom: 25px;
}

#order-age {
    width: 70px;
    border: none;
    outline: none;
    border-bottom: 2px solid #16A7E1;
    text-align: center;
    padding-bottom: 5px;
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center;
    
}

label[for=order-program]{
    width: 390px;
}

#order-program{
    width: 320px;
    border: 2px solid #F9B233;
    border-radius: 10px;
    margin-bottom: 30px;
    min-height: 30px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .order__popup {
        width: 380px;
        margin-left: -190px;
        top: 0;
        margin-top: 0;
    }
    
    .order__description {
        width: 330px;
    }
    label[for=order-program]{
        width: 330px;
    }
}



.transaction-list__item {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e3e3e3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.trans-list-item__order-id {
    width: 170px;
}

.trans-list-item__order-date {
    width: 210px;
}

.trans-list-item__order-user {
    width: 300px;
    margin-right: 20px;
}

.trans-list-item__order-sum {
    font-weight: 600;
    width: 100px;
}

.table-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    border: none;
}
/* @import '~materialize-css/dist/css/materialize.css'; */

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, sans-serif;
    color: rgba(0,0,0,0.87);
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.homing-btn {
    padding: 10px 70px;
    background: #F9A41F;
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    cursor: pointer;
    border: none;
    outline: none;
}

.homing-btn:hover {
    opacity: .8;
}

.homing-btn-yellow { 
    background: #F9A41F;
}

.homing-btn-blue { 
    background: #16A7E1;
}
.homing-btn-red { 
    background: #FF390F;
}

.input-field {
    position: relative;
}

.close {
position: absolute;
right: 32px;
top: 32px;
width: 32px;
height: 32px;
opacity: 0.3;
cursor: pointer;
}
.close:hover {
opacity: 1;
}
.close:before, .close:after {
position: absolute;
left: 15px;
content: ' ';
height: 33px;
width: 2px;
background-color: #333;
}
.close:before {
transform: rotate(45deg);
}
.close:after {
transform: rotate(-45deg);
}

h3 {
    font-size: 42px;
}

.toast {
    border-radius: 2px;
  top: 35px;
  width: auto;
  margin-top: 10px;
  position: relative;
  max-width: 100%;
  height: auto;
  min-height: 48px;
  line-height: 1.5em;
  background-color: #323232;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

#toast-container {
    display: block;
    position: fixed;
    z-index: 10000;
  }

  
@media only screen and (min-width: 601px) and (max-width: 992px) {
    #toast-container {
      left: 5%;
      bottom: 7%;
      max-width: 90%;
    }
  }
  
  @media only screen and (min-width: 993px) {
    #toast-container {
      top: 10%;
      right: 7%;
      max-width: 86%;
    }
  }

input[type=text] {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 30px;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 3px;
    box-shadow: none;
    box-sizing: content-box;
    transition: box-shadow .3s, border .3s;
    
}


.input-field>label {
    position: absolute;
    top: -3px;
    left: 20px;
    font-size: 1rem;
    cursor: text;
    transition: transform .2s ease-out, color .2s ease-out;
    transform-origin: 0% 100%;
    text-align: left;
    text-align: initial;
    transform: translateY(12px);
    font-weight: 800;
}

.input-field>label.active {
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0;
    color: #fff;
}

.container {
    width: 1100px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px){

.container {
    width: 900px;
}

}

@media screen and (max-width: 950px){

    .container {
        width: 650px;
    }
    
}

@media screen and (max-width: 700px){

    .container {
        width: 420px;
    }
}

@media screen and (max-width: 450px){

    .container {
        width: 320px;
    }
    
}


.btn, .btn-small {
    border: none;
    border-radius: 2px;

    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.btn, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: #26a69a;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

select {
    display: inline;
    display: initial;
    margin-bottom: 10px;
}


.p-t {
    padding-top: 100px;
}


.nav-name p {
    margin: 0;
    padding: 0; 
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="radio"]:not(:checked) + span,
[type="radio"]:checked + span {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

[type="radio"] + span:before,
[type="radio"] + span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}

/* Unchecked styles */
[type="radio"]:not(:checked) + span:before,
[type="radio"]:not(:checked) + span:after,
[type="radio"]:checked + span:before,
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border-radius: 50%;
}

[type="radio"]:not(:checked) + span:before,
[type="radio"]:not(:checked) + span:after {
  border: 2px solid #5a5a5a;
}

[type="radio"]:not(:checked) + span:after {
  transform: scale(0);
}

/* Checked styles */
[type="radio"]:checked + span:before {
  border: 2px solid transparent;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #26a69a;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #26a69a;
}

[type="radio"]:checked + span:after {
  transform: scale(1.02);
}

.user-item:hover{
    background-color: rgba(0,0,0,.1);
    cursor: pointer;
}

.lesson-line__order {
    width: 75px;
}

.lesson-line__title {
    width: 120px;
    margin-right: 15px;
}

.lesson-line {
    display: flex;
    justify-content: flex-start;

}

.task-tittle{
    font-size: 24px;
}

.add-user-btn {
    display: flex;
    position: absolute;
    bottom: 50%;
    right: 60px;
    margin-bottom: -16px;
}

.lesson__task {
    width: 60%;
}

.lesson__page {
    background-image: url(https://media3.giphy.com/media/J1Qp5WXgzdG6eZrxHn/giphy.gif?cid=ecf05e47505tb53lwlm0b3hplzzke9egqic6ibxyaydbzypg&rid=giphy.gif);
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: right 100px;
}

.task__add-user-btn {
    width: 50px;
    display: flex;
    position: absolute;
    bottom: 17px;
    right: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.file_download-btn {
    margin-right: 150px;
}



.btn-wrap {
    display: flex;
    align-items: center;
}

.user-item {
    position: relative;
}

.user-link {
    min-height: 50px;
    display: flex;
    justify-content: center;
    color: rgba(0,0,0,0.87);
}

.red {
    background-color: #FF390F;
}

.task-aria {
    width: 250px;
}

.task-item {
    position: relative;
}

.group-creating {
    font-size: 12px!important;
}

.task-line__order {
    width: 150px!important;
}

.task-line__title {
    width: 90px!important;
}

.task_element {
    width: 80%!important;
}


h1,h2,h3,p {
    margin: 0;
    padding: 0;
}

.group-collection {
    background-color: #fff;

    min-height: 200px;
}

.group__loading {
    position: relative;
    left: 420px;
}

.headline--shadow {
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 30px;
    padding: 5px 30px;
}

.page-headline {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 800;

    font-size: 42px;
    margin-bottom: 45px;

}

.section__headline{
    font-size: 32px;
    font-weight: 800;
}

.page-block__image {
    margin-top: 35px;
    width: 100px;
    margin-bottom: 0;
}

.page-block__image img {
    width: 100%;
    height: auto;
}

.add-shedule {
    align-items: center;
    display: flex;
}

#shadule_new_date {
    width: 100px;
}



.main-page_wrap {
    position: relative;
    
}

.links-item {
    margin-right: 10px;
}

#root {
    position: relative;
}

.updater-wrap{
    background-color: #eee;
}

.updater-list{
    display: flex;
    justify-content: space-between;
}

.users-wrap {
    padding: 15px;
    border: 2px solid #747474;
    padding-top: 0;
    margin-bottom: 15px;
}

.user-list {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
}

.user-list__item {
    align-self: flex-start;
    width: 300px;
}

.img-wrap {
    margin-top: 15px;
}

.instr-block {
    border-bottom: 2px solid #e5e5e5;
    padding-bottom: 20px;
}

.task-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-line__image {
    width: 250px;
    cursor: pointer;
}

.big-image {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.3);
}

.task-line__image.big-image img{
    width: 250px;
    height: auto;
    transform: scale(2);
}

.task-line__image img {
    width: 100%;
    height: auto;
    max-height: 100vh;
}

input[type=file]{
position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
}

.visitors-wrap {
    margin: 0;
    padding: 0;
    width: 42px;
    padding-left: 7px;
}

.shedule-line {
    display: flex;
}

.date-item {
    display: flex;
    align-items: center;
    width: 42px;
    font-weight: bold;
}

.shedule-line h5 {
    width: 300px;
}

.file-upload {
    cursor: pointer;
}

.visitors-wrap__hours{
    text-align: center;
    width: 18px;
}

.users-wrap__hours {
    padding-left: 300px;
}

.visitors-wrap__hours{
    display: none;
    height: 20px;
}
#shadule_new_hours {
    width: 50px;
    margin-left: 5px;
}

.user-list:last-child .visitors-wrap__hours{
    display: block;
}

.exercise_btn {
    margin-top: 15px;
    margin-bottom: 15px;
}

.exercise--complite {
    padding: 20px;
    border-radius: 20px;
    background-color: #02b241;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.vlesson {
    text-align: center;
}



.vlesson-link {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    height: 100%;
    padding: 20px;
    padding-left: 45px;
    color: rgba(0,0,0,0.87);;
}

.collection-item {
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
}


label>input[type=radio] {
    background-color: black;
}

input[type=radio]::before {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid black;
    border-radius: 50%;
}

.user-item.vlesson-item {
    font-size: 18px;
    font-weight: 500;
    padding: 0;
    border-radius: 10px;
}

.collection {
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    box-shadow: 6px 0px 13px rgba(0, 0, 0, 0.25);
    
}



.lesson--complite {
    margin-right: 10px;
    color:#fff;
    border-radius: 50%;
    background-color: rgb(55, 187, 31);
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10px;
    font-size: 14px;
    top: 50%;
    margin-top: -12.5px;
}

.misstake{
    position: relative;
}

.misstake::before{
    content: "Ошибка! Попробуйте ещё раз 😉";
    color: red;
    position: absolute;
    top: -20px;
    left: 1px;
}

.variant_text span {
    color: rgb(24, 24, 24);
}

.variants_true .variant_text span {
    color: #757575;
}

label.truth_var span {
    color: rgb(255, 255, 255)!important;
    background-color: rgb(35, 243, 76);
    padding-right: 10px;
    border-radius: 10px;

}

label.truth_var span::before{
    content: "✔";
    border: none !important;
    top:-5px;
    left: 3px;
}

label.truth_var span::after{
    border: none !important;
    background-color: transparent !important;
}

.updater-list{
    display: none;
}

.group-corr_open .updater-list{
    display: flex;
}

.file_label {
    font-size: 10px;
    width: 75px;
    overflow: hidden;
    height: 12px;
}

button:disabled{
    background-color: grey;
}

.exercise-hint {
    font-family: "Roboto" ,Arial, sans-serif;
    color: grey;
    font-size: 14px;
}

.section__header {
    font-family: 'Press Start 2P', Arial,sans-serif;
    font-size: 28px;
    margin-bottom: 35px;
    margin-top: 60px;
}

.vlesson__title{
    font-size: 18px;
    text-transform: capitalize;
    color:#16a7e1;
}

.arrow_img{
    width: 16px;
    top:50%;
    margin-top: calc(16px / 2 *-1);
    transform: rotate(180deg);
    position: absolute;
    left: 15px;
}

.vlessons-list {
    padding-bottom: 25px;
}

.vlesson-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vlesson-row:hover {
    background-color: rgb(206, 242, 253);
}

.vlesson-menu__headline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vlesson-menu__btn {
    margin-top: 15px;
}

p.homing-small-blue-text {
    color: #162ae1;
    font-size: 16px;
    text-align: center;
    margin-top: 7px;
    cursor: pointer;
}

p.homing-small-blue-text:hover {
    opacity: .5;
}


.loading-container {
    position: relative;
}


@media screen and (max-width: 500px){
    .main-page__block {
        width: 300px;
        font-size: 12px;
        min-height: 200px;
    }

    .price-page__decoration-line {
        width: 300px;
    }

    .main-page__headline  {
        
        font-size: 22px!important;
        margin-bottom: 26px;
    }

    .vlesson {
        padding-top: 24px;
    }

    .page-headline{
        font-size: 28px;
    }

    .add-shedule {
        flex-wrap: wrap;
    }

    .add-user-btn {
        flex-direction: column;
        width: 35px;
        font-size: 12px;
        margin-bottom: 0;
        bottom: 0px;
        right: 10px;
    }
    .add-user-btn button,
    .add-user-btn a{
        height: 20px;
        font-size: 8px;
        padding: 0;
        margin-bottom: 5px;
        line-height: 1;
    }

    .add-user-btn a b {
        font-size: 16px;
    }

    .lesson-line {
        flex-direction: column;
        min-height: 75px;
        justify-content: space-between;
    }

    .task-tittle{
        font-size: 16px;
    }

    .lesson__task,
    .lesson-line__title {
        width: auto;
    }

    .group-corr_open .updater-list {
        flex-direction: column;
    }

    .users-wrap {
        display: none;
    }

    .input-field {
        margin: 0;
    }
    
    .updater-wrap{
        background-color: transparent;
    }
}


.start_wrap {
    --homing_blue: #0496FF;
    --homing_white: #F8F7FF;
    --homing_black: #272525;
    --homing_red: #D81159;

    font-family: "Montserrat", Arial, sans-serif;

}

.start__container {
    width: 320px;
    margin: 0 auto;
}

.start__header {
    background-color: var(--homing_blue);
    color: var(--homing_white);
}

.start__header h1 {
    font-family: "Fira Mono", Arial, sans-serif;
    font-size: 24px;
    width: 278px;
    text-align: center;
    margin-bottom: 18px;
}

.start-header__wrap {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.start-header__decor {
    position: relative;
    width: 85px;
    height: 85px;
    border-radius: 50%;

    background-color: #FFBC42;
    margin-bottom: 24px;
}

.start-header__description {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 46px;

    font-family: "Montserrat", Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #F8F7FF;
}

.start__btn {
    border-radius: 10px;

    font-family: "Fira Mono", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: var(--homing_black);
    cursor: pointer;
}

.start__btn:hover {
    opacity: .7;
}

.start__btn:active {
    opacity: .5;
}

.start__begin-btn {
    width: 179px;
    height: 35px;

    background: #FFBC42;
}

.start__login-btn {
    width: 160px;
    height: 30px;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.6);
    /* border: 2px solid #D81159; */
    box-sizing: border-box;

    margin-bottom: 40px;
}

.about,
.why,
.mentors,
.call-t-a {
    color: var(--homing_black);
    padding-top: 40px;
}

.about__decor {
    width: 130px;
    height: 50px;

    background: #D81159;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.start__headline {
    width: 237px;
    margin-bottom: 24px;

    font-family: "Fira Mono", Arial, sans-serif;
    font-weight: 700;
    font-size: 24px;

    color: #272525;
}

.about__description {
    width: 241px;
    margin-bottom: 32px;

    font-family: "Montserrat", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.about__bottom-decor {
    width: 67%;
    height: 3px;

    background-color: var(--homing_red);
    border-radius: 0px 10px 10px 0px;
}

.why__decor {
    width: 85px;
    height: 85px;
    border-radius: 50%;

    background-color: #FFBC42;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.why__headline {
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.advantage__decor {
    width: 29px;
    height: 29px;
    border-radius: 50%;

    background-color: #0496FF;
    margin-right: 16px;
    position: relative;
}

.advantage__content {
    width: 188px;
}

.advantage-content__headline {
    font-size: 16px;
    margin-bottom: 16px;
    padding-top: 4px;
}

.advantage-list__item {
    display: flex;
    margin-bottom: 24px;
}

.why__advantage-list {
    margin-bottom: 32px;
}

.why__bottom-decor {
    width: 67%;
    height: 3px;

    border-radius: 0px 10px 10px 0px;
    background-color: var(--homing_red);
    margin-left: auto;
}

.why .start__btn{
    margin-left: auto;
    margin-right: auto;
}

.mentors__decor {
    width: 132px;
    height: 51px;

    background: #FFCA3A;
    border-radius: 10px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
}

.mentors__btn {
    background-color: var(--homing_red);
    color: var(--homing_white);
    width: 208px;
    height: 29px;
}

.c-t-a__btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
}

.start__footer {
    background-color: rgba(4, 150, 255, 0.2);
    padding-top: 32px;
    padding-bottom: 48px;
}

.footer__headline {
    font-size: 16px;
    margin-bottom: 16px;
}

.footer-list__item {
    margin-bottom: 8px;
}

.footer__links-list {
    margin-bottom: 32px;
}

.quiz__label {
    width: 100px;
    height: 30px;
}

.quiz__label {
    width: 225px;
    height: 45px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 24px;
    font-weight: 600;
}

.quiz-page__wrap {
    padding-top: 48px;
    background-color: #0496ff6b;
    min-height: calc(100vh - 75px);
    height: 100%;
}

.quiz__label label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.quiz__label:hover {
    box-shadow: #D81159 0px 0px 10px 0px;
}

.quiz__label--checked {
    background-color: #FFBC42;
}

.quiz__headline {
    font-family: "Fira Mono", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 48px;
}

.question-list__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question__next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #F8F7FF;
    width: 225px;
    height: 45px;
    border-radius: 10px;
    background-color: #D81159;
    margin-top: 32px;

    margin-right: auto;
    margin-left: auto;
}

.question__next-btn:hover {
    opacity: .7;
}

.question__next-btn:active{
    opacity: .5;
} 

.reg-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input.reg-form__input {
    width: 270px;
    height: 40px;
    background-color: #fff;
    border-radius: 10px;
    border: none;
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
}

.reg-form__submit {
    display: block;
    width: 270px;
    height: 45px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    background: #FFCA3A;

    margin-top: 24px;
}

.reg-form__submit:hover {
    opacity: .8;
}

.reg-form__submit:active {
    opacity: .5;
}

.quiz-page__progress-bar {
    height: 8px;
}

.progress-bar__progress {
    background-color: #D81159;
    height: 100%;
    width: 15%;
}

.progress-bar__progress--25 {
    width: 25%;
}

.progress-bar__progress--50 {
    width: 50%;
}

.progress-bar__progress--75 {
    width: 75%;
}

.progress-bar__progress--90 {
    width: 90%;
}

.thanks-page__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--homing_black);
    margin-left: auto;
    margin-right: auto;
}

.thank-page__description {
    width: 270px;
    margin-bottom: 64px;
}

.thanks-page__design {
    width: 70px;
    height: 70px;
    background-color: var(--homing_red);
    position: relative;
    border-radius: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 48px;
}

.thank-page__headline {
    padding-top: 24px;
    font-size: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
    font-family: 'Fira Mono', "Arial", sans-serif;
}

.thanks-page__img {
    width: 100px;
    position: absolute;
    left: -40px;
    top: -5px;
}

div.toast {
    display: block!important;
    bottom: 10px;
    right: 10px;
    opacity: 1;
}

.confirm-poicy {
    margin-top: 16px;
    font-size: 10px;
    text-align: center;
    width: 270px;   
}

.reg-form__desc {
    color: var(--homing-black);

    font-size: 16px;
    text-align: center;
    width: 250px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 32px;
}


@media only screen and (max-width: 600px) {
    #toast-container {
      min-width: 100%;
      bottom: 0%;
    }
  }

