.price-page__decoration-line {
    margin-top: 10px;
    width: 400px;
    height: 4px;

    background: #16A7E1;
}

.price-page__headline {
    margin-top: 28px;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
}

.fast-desc {
    display: flex;
    width: 360px;
    justify-content: space-between; 
    margin-top: 25px;
    margin-bottom: 25px;
}

.fast-desc__item {
    display: flex;
    align-items: center;
}


.desc-item__number {
    font-style: normal;
    font-weight: 900;
    font-size: 46px;
    line-height: 54px;

    color: #000000;
    margin-right: 5px;
}

.desc-item__content {
    width: 118px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
}

.price__plans-line {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plans-line__item {
    position: relative;
    padding: 28px 23px;
    box-sizing: border-box;
    width: 310px;
    min-height: 386px;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
}

.line-item__description-list li{
    position: relative;
}

.line-item__description-list li::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    background-color: #FF390F;
    left: -15px;
    top: 5px;
}

.price-page__disclamer {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-size: 9px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 85px;
}

.line-item__h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;

    color: #000000;

    width: fit-content;
    margin-bottom: 25px;
}

.line-item__price {
    font-weight: 900;
    font-size: 42px;
    line-height: 49px;

    color: #000000;
    margin-bottom: 15px;
}

.price__small-desc {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
}

.line-item__description-list {
    padding-left: 15px;

}

.line-item__description-list li{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;

    color: #000000;
}
.line-item__button{
    cursor: pointer;
    font-size: 24px;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    width: 270px;
    height: 45px;
    padding-top: 10px;

    box-sizing: border-box;
    background: #FF390F;
    border-radius: 30px;

    margin-top: auto;
}

.mentor-item {
    box-shadow: none;
    background-color: transparent;
    color: #fff;
    background-color: #16A7E1;
}

.mentor-item p,
.mentor-item span,
.mentor-item li,
.mentor-item h3
{
    color: #fff;
}

.mentor-item .line-item__description-list li::before {
    background-color: #F9A41F;
}

.mentor-item .line-item__button {
    background-color: #F9A41F;
}

.price-page__top-line {
    display: flex;
}

.top-line__image {
    width: 200px;
}

.top-line__description {
    margin-left: 100px;
    display: flex;
    align-items:flex-start;
}

.top-line__description p{
    padding-top: 10px;
    width: 300px;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-line__description ul{
    padding-left: 15px;
}

.top-line__description li{
    font-size: 14px;
    font-weight: bold;
    position: relative;
}

.top-line__description li::before{
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    background-color: #F9A41F;
    top: 4px;
    left: -10px;
}