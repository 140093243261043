@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&display=swap');

.start_wrap {
    --homing_blue: #0496FF;
    --homing_white: #F8F7FF;
    --homing_black: #272525;
    --homing_red: #D81159;

    font-family: "Montserrat", Arial, sans-serif;

}

.start__container {
    width: 320px;
    margin: 0 auto;
}

.start__header {
    background-color: var(--homing_blue);
    color: var(--homing_white);
}

.start__header h1 {
    font-family: "Fira Mono", Arial, sans-serif;
    font-size: 24px;
    width: 278px;
    text-align: center;
    margin-bottom: 18px;
}

.start-header__wrap {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.start-header__decor {
    position: relative;
    width: 85px;
    height: 85px;
    border-radius: 50%;

    background-color: #FFBC42;
    margin-bottom: 24px;
}

.start-header__description {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 46px;

    font-family: "Montserrat", Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #F8F7FF;
}

.start__btn {
    border-radius: 10px;

    font-family: "Fira Mono", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: var(--homing_black);
    cursor: pointer;
}

.start__btn:hover {
    opacity: .7;
}

.start__btn:active {
    opacity: .5;
}

.start__begin-btn {
    width: 179px;
    height: 35px;

    background: #FFBC42;
}

.start__login-btn {
    width: 160px;
    height: 30px;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.6);
    /* border: 2px solid #D81159; */
    box-sizing: border-box;

    margin-bottom: 40px;
}

.about,
.why,
.mentors,
.call-t-a {
    color: var(--homing_black);
    padding-top: 40px;
}

.about__decor {
    width: 130px;
    height: 50px;

    background: #D81159;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.start__headline {
    width: 237px;
    margin-bottom: 24px;

    font-family: "Fira Mono", Arial, sans-serif;
    font-weight: 700;
    font-size: 24px;

    color: #272525;
}

.about__description {
    width: 241px;
    margin-bottom: 32px;

    font-family: "Montserrat", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.about__bottom-decor {
    width: 67%;
    height: 3px;

    background-color: var(--homing_red);
    border-radius: 0px 10px 10px 0px;
}

.why__decor {
    width: 85px;
    height: 85px;
    border-radius: 50%;

    background-color: #FFBC42;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.why__headline {
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.advantage__decor {
    width: 29px;
    height: 29px;
    border-radius: 50%;

    background-color: #0496FF;
    margin-right: 16px;
    position: relative;
}

.advantage__content {
    width: 188px;
}

.advantage-content__headline {
    font-size: 16px;
    margin-bottom: 16px;
    padding-top: 4px;
}

.advantage-list__item {
    display: flex;
    margin-bottom: 24px;
}

.why__advantage-list {
    margin-bottom: 32px;
}

.why__bottom-decor {
    width: 67%;
    height: 3px;

    border-radius: 0px 10px 10px 0px;
    background-color: var(--homing_red);
    margin-left: auto;
}

.why .start__btn{
    margin-left: auto;
    margin-right: auto;
}

.mentors__decor {
    width: 132px;
    height: 51px;

    background: #FFCA3A;
    border-radius: 10px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
}

.mentors__btn {
    background-color: var(--homing_red);
    color: var(--homing_white);
    width: 208px;
    height: 29px;
}

.c-t-a__btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
}

.start__footer {
    background-color: rgba(4, 150, 255, 0.2);
    padding-top: 32px;
    padding-bottom: 48px;
}

.footer__headline {
    font-size: 16px;
    margin-bottom: 16px;
}

.footer-list__item {
    margin-bottom: 8px;
}

.footer__links-list {
    margin-bottom: 32px;
}

.quiz__label {
    width: 100px;
    height: 30px;
}

.quiz__label {
    width: 225px;
    height: 45px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 24px;
    font-weight: 600;
}

.quiz-page__wrap {
    padding-top: 48px;
    background-color: #0496ff6b;
    min-height: calc(100vh - 75px);
    height: 100%;
}

.quiz__label label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.quiz__label:hover {
    box-shadow: #D81159 0px 0px 10px 0px;
}

.quiz__label--checked {
    background-color: #FFBC42;
}

.quiz__headline {
    font-family: "Fira Mono", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 48px;
}

.question-list__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question__next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #F8F7FF;
    width: 225px;
    height: 45px;
    border-radius: 10px;
    background-color: #D81159;
    margin-top: 32px;

    margin-right: auto;
    margin-left: auto;
}

.question__next-btn:hover {
    opacity: .7;
}

.question__next-btn:active{
    opacity: .5;
} 

.reg-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input.reg-form__input {
    width: 270px;
    height: 40px;
    background-color: #fff;
    border-radius: 10px;
    border: none;
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
}

.reg-form__submit {
    display: block;
    width: 270px;
    height: 45px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    background: #FFCA3A;

    margin-top: 24px;
}

.reg-form__submit:hover {
    opacity: .8;
}

.reg-form__submit:active {
    opacity: .5;
}

.quiz-page__progress-bar {
    height: 8px;
}

.progress-bar__progress {
    background-color: #D81159;
    height: 100%;
    width: 15%;
}

.progress-bar__progress--25 {
    width: 25%;
}

.progress-bar__progress--50 {
    width: 50%;
}

.progress-bar__progress--75 {
    width: 75%;
}

.progress-bar__progress--90 {
    width: 90%;
}

.thanks-page__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--homing_black);
    margin-left: auto;
    margin-right: auto;
}

.thank-page__description {
    width: 270px;
    margin-bottom: 64px;
}

.thanks-page__design {
    width: 70px;
    height: 70px;
    background-color: var(--homing_red);
    position: relative;
    border-radius: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 48px;
}

.thank-page__headline {
    padding-top: 24px;
    font-size: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
    font-family: 'Fira Mono', "Arial", sans-serif;
}

.thanks-page__img {
    width: 100px;
    position: absolute;
    left: -40px;
    top: -5px;
}

div.toast {
    display: block!important;
    bottom: 10px;
    right: 10px;
    opacity: 1;
}

.confirm-poicy {
    margin-top: 16px;
    font-size: 10px;
    text-align: center;
    width: 270px;   
}

.reg-form__desc {
    color: var(--homing-black);

    font-size: 16px;
    text-align: center;
    width: 250px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 32px;
}


@media only screen and (max-width: 600px) {
    #toast-container {
      min-width: 100%;
      bottom: 0%;
    }
  }
